import React, { useEffect, useState } from "react";
import { Spin } from "antd";

import { useMutation, gql, useQuery } from "@apollo/client";

import { ReactComponent as CloseButton } from "../../styles/closeButton.svg";
import { ReactComponent as CheckFilled } from "../../styles/CheckFilled.svg";
import moment from "moment";
import { useToastContext } from "../../core/components/Toast/ToastContext";

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: RestartPassword!) {
    resetPassword(input: $input) {
      response
      success
    }
  }
`;

const GET_IMAP_USERNAME = gql`
  query {
    getSecretEnv
  }
`;

const GET_SECRET_DATE = gql`
  query {
    getSecretDate {
      CreatedDate
      Name
    }
  }
`;

export default function MiHIN() {
  const { showToast } = useToastContext();

  const [resetPassword, { loading, data }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      console.log("on complete", data);
      setFirstPassword("");
      setSecondPassword("");

      showToast("success", data.resetPassword.response);
    },
    onError: (data: any) => {
      console.log(data.message);
      showToast("error", data.message);
    },
  });

  const { data: getSecret, loading: isImapUsernameLoading } = useQuery(
    GET_SECRET_DATE,
    {
      onCompleted: (data:any) => {
        console.log("secret data", data);
        setImap_username(data.getSecretDate.Name);
      },
      onError: (error:any) => {
        console.log(error);
      } 
    }
  );
  console.log(getSecret)
  const [imap_username, setImap_username] = useState("");
  const [firstPassword, setFirstPassword] = useState("");
  const [secondPassword, setSecondPassword] = useState("");

  const handleReset = () => {
    if (
      firstPassword !== secondPassword ||
      firstPassword === "" ||
      secondPassword === ""
    ) {
      showToast("error", "Passwords do not match. Please, try again.");
    } else {
      resetPassword({
        variables: { input: { password: firstPassword, imap_username } },
      });
    }
  };

  return (
    <div className="container">
      {isImapUsernameLoading ? (
        <div className="flex-center" style={{ margin: "10px 0" }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <h3 className="page-title">Password reset</h3>

          <h4 className="page-small-title" style={{ marginTop: "50px" }}>
            Instruction steps for Diretto password update:
          </h4>
          <div className="page-row">
            <span className="page-step">1.</span>{" "}
            <span className="page-description">
              Follow this link to the MiHIN Diretto portal:{" "}
              <a
                href="https://mihin.net/diretto-password-reset"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mihin.net/diretto-password-reset
              </a>
            </span>
          </div>

          <div className="page-row">
            <span className="page-step">2.</span>{" "}
            <span className="page-description">
              Change password on the Diretto portal.
            </span>
          </div>
          <div className="page-row">
            <span className="page-step">3.</span>{" "}
            <span className="page-description">
              Return back to Zilius portal.
            </span>
          </div>

          <div className="page-row">
            <span className="page-step">4.</span>{" "}
            <span className="page-description">
              Enter your username and the new password that was updated in the
              Diretto portal.
            </span>
          </div>

          <h4 className="page-small-title" style={{ marginTop: "50px" }}>
            Update Diretto credentials
          </h4>
          <h5 className="page-description">
            Your password was last updated on{" "}
            {moment(getSecret?.getSecretDate?.CreatedDate).format("MM/DD/YYYY")}
          </h5>

          <div className="mihin-form">
            <label htmlFor="IMAP_username">IMAP username</label>
            <input
              id="IMAP_username"
              type="text"
              value={imap_username}
              onChange={(e) => setImap_username(e.target.value)}
            />

            <label htmlFor="IMAP_password">Enter your new password</label>
            <input
              id="IMAP_password"
              type="password"
              value={firstPassword}
              onChange={(e) => setFirstPassword(e.target.value)}
            />

            <label htmlFor="IMAP_password_repeat">
              Repeat your new password
            </label>
            <input
              id="IMAP_password_repeat"
              type="password"
              value={secondPassword}
              onChange={(e) => setSecondPassword(e.target.value)}
            />
          </div>

          <div className="mihin-button-row">
            {loading ? (
              <div className="flex-center" style={{ margin: "10px 0" }}>
                <Spin size="small" />
              </div>
            ) : (
              <button className="mihin-button" onClick={handleReset}>
                Update password
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
